<template>
  <header class="nav nav-transparent d-flex justify-content-between align-items-center">
    <b-link to="/" class="nav-logo">
      <img width="165" height="77" src="/static/img/tayc-r-logo-color.svg" alt="TAYC-R logo">
    </b-link>
  </header>
</template>

<script>
export default {
  name: 'NavigationSurvey',
}
</script>
