import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import Axios from 'axios'
import {apiConfig} from './apiConfig'
Vue.use(Vuex)

// Detect Browser
var { detect } = require('detect-browser')
var browser = detect()
if (browser) {
  console.log(browser.name, browser.version, browser.os)
}


export default new Vuex.Store({
  state: {
    addToHomeScreenBtnShow: false,
    alertMsg: {
      msg: '',
      success: true,
      show: false
    },
    appDomain: window.location.hostname,
    appVersion: '1.1.0',
    appYear: new Date().getFullYear(),
    appSection: '',
    browser: browser,
    connectivity: true,
    loadingProgress: true,
    modalInfo: {},
    signInProcess: false,
    signInProcessPhoneToAdd: null,
    jwt: null,
    jwtDecoded: null,
    setupForm: null,
    sessionExpired: false,
    user: {
      role: null,
      email: null,
      name: null
    },
    userData: null,
    organisationCredits: null
  },
  mutations: {
    signOut (state) {
      console.log(state)
      console.log('session expired')
      state.user = null
      localStorage.removeItem('jwt')
      state.sessionExpired = true
      router.push('/sign-in')
    },
    setUserData (state, data) {
      console.log('setUserData', data)
      state.userData = data
    },
    setOrganisationCredits (state, data) {
      state.organisationCredits = data
    }, 
    showAlertMsg (state, data) {
      if (data === 'accessDenied') {
        state.alertMsg = {
          msg: "Access denied, you don't have permission to access this page",
          success: false,
          show: true
        }
      } 
    }
  },
  actions: {
    async getUserData({state, commit}) {
      // Therapist accounts have additional data
      Axios({
        method: 'get', 
        url: apiConfig.AccountPersonalDetailsGet,
        headers: {'Authorization': 'bearer ' + state.jwt } 
      })
        .then((response) => {
          console.log('endpoint response', response)
          commit('setUserData', response.data)
        })
        .catch((error) => {
          console.error('Error', error)
        })
    }
    
  },
  modules: {
  }
})
