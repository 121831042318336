<template>
  <div class="preloader loading-screen-dark">
    <div class="preloader-content">
      <h1 class="mb-3"><i class="icon-cancel text-danger mr-2 anim-shake-no"></i>Connection is lost</h1>
      <p>The app will automatically reconnect when online. <br>Please check your internet connection.</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NetworkIndicator'
}
</script>