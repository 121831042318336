export function watchForHover() {
  var hasHoverClass = true;
  var container = document.body;
  var lastTouchTime = 0;

  function enableHover() {
      // filter emulated events coming from touch events
      if (new Date() - lastTouchTime < 500) return;
      if (hasHoverClass) return;

      // container.className += ' hasHover';
      container.classList.remove('hoverDisabled');
      hasHoverClass = false;
  }

  function disableHover() {
      if (!hasHoverClass) return;

      // container.className = container.className.replace(' hasHover', '');
      container.classList.add('hoverDisabled');
      hasHoverClass = true;
  }

  function updateLastTouchTime() {
      lastTouchTime = new Date();
  }

  document.addEventListener('touchstart', updateLastTouchTime, true);
  document.addEventListener('touchstart', disableHover, true);
  document.addEventListener('mousemove', enableHover, true);

  enableHover();
}

