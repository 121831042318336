var uiValues = {
  
  titleOptions: [
    { value: 'Mr', text: 'Mr' },
    { value: 'Mrs', text: 'Mrs' },
    { value: 'Miss', text: 'Miss' },
    { value: 'Ms', text: 'Ms' },
    { value: 'Dr', text: 'Dr' }
  ],

  phoneCodes: [
    { value: '+44', text: '+44', reqNumberLength: 10},
    { value: '+353', text: '+353', reqNumberLength: 9 }
  ],

  supportEmail: 'taycr@adoptionplus.co.uk',

  configDateEntry: {
    dateFormat: "Y-m-d",
    altFormat: "d/m/Y",
    altInput: true,
    minDate: "today",
    shorthandCurrentMonth: false,
    disableMobile: true,
    locale: {
      firstDayOfWeek: 1
    },
    nextArrow: '<i class="icon icon-right-open"></i>',
    prevArrow: '<i class="icon icon-left-open"></i>',
    static: true,
    position: 'below'
  },

  configTime: {
    enableTime: true,
    noCalendar: true,
    dateFormat: 'H:i',
    enableSeconds: false,
    time_24hr: true, 
    wrap: true
  },

  surveyChartOptions: {
    colors:['#e6007e', '#adb5bd'],
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top"
        }
      }
    },
    chart: {
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "14px",
        colors: ["#fff"]
      }
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"]
    },
    xaxis: {
      show: false,
      categories: [''],
      labels: {
        show: false
      }
    },
    tooltip: {
      enabled: false
    }
  },

  barChartOptions: {
    colors: ['#D3164B'],
    xaxis: {
      categories: []
    },
    yaxis: [
      {
        labels: {
          formatter: function(val) {
            return val.toFixed(0);
          }
        }
      }
    ],
    chart: {
      height: 350,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        columnWidth: '60%',
        borderRadius: 5,
        dataLabels: {
          position: 'top',
        },
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ["#304758"]
      }
    },
  },
}

export {
  uiValues
}