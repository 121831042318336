<template>
  <header class="nav d-flex justify-content-between align-items-center">
    <b-link to="/" class="nav-logo">
      <img width="165" height="77" src="/static/img/tayc-r-logo-color.svg" alt="TAYC-R logo">
    </b-link>

    <div class="toggle" v-bind:class="{'icon-menu': !mobileNavOpen, 'icon-cancel': mobileNavOpen}" v-on:click="toggleMobileNav()"></div>

    <nav class="d-md-flex" v-bind:class="{'nav-open': mobileNavOpen}" v-on:click="mobileNavOpen = false">
      <b-link to="/dashboard" v-if="userSignedIn !== false"><i class="icon icon-th-large mr-2 pl-0"></i>Dashboard</b-link>
      <b-link to="/account-settings" v-if="userSignedIn !== false" class="nav-item-data">
        <span><i class="icon icon-user mr-2 pl-0"></i>Account settings</span>
        <span v-if="userDisplay !== null" class="nav-item-data-label">{{userDisplay}}</span>
      </b-link>
      <b-link v-on:click="signOut()" v-if="userSignedIn !== false">Sign out</b-link>
      <b-link to="/register-interest" v-if="userSignedIn === false">Register interest</b-link>
      <b-link to="/faq" v-if="userSignedIn === false">FAQs</b-link>
      <b-link to="/sign-in" v-if="userSignedIn === false">Sign in</b-link>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Navigation',
  data () {
    return {
      mobileNavOpen: false
    }
  },
  computed: {
    userSignedIn () {
      if (this.$store.state.user === null || this.$store.state.user.email === null || this.$store.state.user.email === '') {
        return false
      } else {
        return true
      }
    },
    userDisplay() {
      if (this.$store.state.userData?.firstName) {
        return `${this.$store.state.userData?.title} ${this.$store.state.userData?.firstName} ${this.$store.state.userData?.lastName}`
      } else {
        return null
      }
    },
  },
  methods: {
    toggleMobileNav: function () {
      this.mobileNavOpen = !this.mobileNavOpen
    }
  }
}
</script>
