import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import Axios from 'axios'
import {apiConfig} from './apiConfig'
import routesBrochure from './routesBrochure'
import routesApp from './routesApp'

var jwtDecode = require('jwt-decode')

var routesAll = routesBrochure.concat(routesApp);

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routesAll,
  scrollBehavior () {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 100)
    })
  }
})

function loadJwt (jwt) {
  if (jwt === null) {
    return false
  }
  store.state.jwt = jwt
  var decoded = jwtDecode(jwt)
  console.log('decoded token', decoded)
  store.state.jwtDecoded = decoded
  var role = decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
  store.state.user = {
    role: role,
    roles: [],
    email: decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'],
    name: decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
    id: decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'],
  }

  // determine roles (they are stored as arrays or as strings)
  if (Array.isArray([role])) {
    store.state.user.roles = role
  } else {
    store.state.user.roles.push(role)
  }  
}


router.beforeEach(async (to, from, next) => { 
  console.log(to)
  store.state.loadingProgress = true
  var jwt = localStorage.getItem('jwt')
  var dateNow = Date.now()
  loadJwt(jwt)

  if (!to.matched[0].meta.requiresAuth) {
    // === View does not require jwt
    next()
    return false

  } else {
    // === View requires jwt
    // console.log('auth', jwt)
    if (jwt === null || jwt === undefined || jwt === '') {
      next({ path: '/sign-in' })
      return false
    } else {
      // loadJwt(jwt)
      console.log('user set in router', store.state.user)

      // Detect token expired
      if (store.state.jwtDecoded !== null && store.user !== null && (dateNow > store.state.jwtDecoded.exp*1000)) {
        console.log('token expired')
        store.commit('signOut')
      }

      // Detect wrong roles
      if (store.state.user !== null && store.state.user.roles.includes('ConfirmPhone')) {
        console.log('Unknown role detected')
        next({ path: '/sign-in' })
        return false
      }

      // Manipulate roles for testing
      // if (store.state.user !== null && store.state.user.roles.includes('Developer')) {
      //   store.state.user.roles = ['Admin', 'Developer']
      // }

      // call backend to check if token is valid
      Axios({
        method: 'get', 
        url: apiConfig.AuthTokenValidate,
        headers: { 'Authorization': 'Bearer ' + store.state.jwt } 
      })
      .then(function (response) {
        console.log(response)
        if (response.status !== 200) {
          console.log('token expired')
          store.commit('signOut')
        }
      })
      .catch(function (error) {
        console.log(error)
        store.commit('signOut')
      })


      // If Therapist role present, load additional data, at the app load, if data not present
      if (store.state.user.roles.includes('Therapist')) {
        await store.dispatch('getUserData')
      }

      // Check if view requires Admin
        if (to.matched[0].meta.requiresAdmin && !store.state.user.roles.includes('Admin') && !store.state.user.roles.includes('OrgSuperAdmin') ) {
        store.commit('showAlertMsg', 'accessDenied')
        store.state.loadingProgress = false
        next({ path: '/dashboard'})
        return false
      }

      // Check if view requires OrgAdmin
      if (to.matched[0].meta.requiresOrgAdmin && !store.state.user.roles.includes('OrgAdmin')) {
        store.commit('showAlertMsg', 'accessDenied')
        store.state.loadingProgress = false
        next({ path: '/dashboard'})
        return false
      }
      
      next()
      return false
    }
  }
})

export default router