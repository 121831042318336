export default [
  {
    path: '/sign-in/',
    name: 'Signin',
    component: () => import(/* webpackChunkName: "Signin" */ './components/views/Signin/Signin.vue')
  },
  {
    path: '/sign-in-verification/',
    name: 'SigninVerification',
    component: () => import(/* webpackChunkName: "SigninVerification" */ './components/views/Signin/SigninVerification.vue')
  },
  {
    path: '/sign-up/',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "Signup" */ './components/views/Signup/Signup.vue')
  },
  {
    path: '/confirm-account/',
    name: 'SignupConfirm',
    component: () => import(/* webpackChunkName: "SignupConfirm" */ './components/views/Signup/SignupConfirm.vue')
  },
  {
    path: '/sign-up-admin/',
    name: 'SignupAdmin',
    component: () => import(/* webpackChunkName: "SignupAdmin" */ './components/views/Signup/SignupAdmin.vue')
  },
  {
    path: '/verification/',
    name: 'Verification',
    component: () => import(/* webpackChunkName: "Verification" */ './components/views/Signup/Verification.vue')
  },
  {
    path: '/password-reset/',
    name: 'PasswordReset',
    component: () => import(/* webpackChunkName: "PasswordReset" */ './components/views/Signin/PasswordReset.vue')
  },
  {
    path: '/dashboard/',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ './components/views/Dashboard/Dashboard.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account-settings/',
    name: 'AccountSettings',
    component: () => import(/* webpackChunkName: "AccountSettings" */ './components/views/Dashboard/AccountSettings.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin-management/',
    name: 'AdminManager',
    component: () => import(/* webpackChunkName: "AdminManager" */ './components/views/Dashboard/AdminManager.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/members-management/',
    name: 'MembersManager',
    component: () => import(/* webpackChunkName: "MembersManager" */ './components/views/Dashboard/MembersManager.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      requiresOrgAdmin: true
    }
  },
  {
    path: '/members-management/invite',
    name: 'MemberAdd',
    component: () => import(/* webpackChunkName: "MemberAdd" */ './components/views/Dashboard/MemberAdd.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      requiresOrgAdmin: true
    }
  },
  {
    path: '/members-management/invites/:id',
    name: 'MemberInviteEdit',
    props: true,
    component: () => import(/* webpackChunkName: "MemberInviteEdit" */ './components/views/Dashboard/MemberInviteEdit.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      requiresOrgAdmin: true
    }
  },
  {
    path: '/members-management/:id',
    name: 'MemberEdit',
    props: true,
    component: () => import(/* webpackChunkName: "MemberEdit" */ './components/views/Dashboard/MemberEdit.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
      requiresOrgAdmin: true
    }
  },
  {
    path: '/profile/invite',
    name: 'ProfileAdd',
    component: () => import(/* webpackChunkName: "ProfileAdd" */ './components/views/Dashboard/ProfileAdd.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/:id',
    name: 'ProfileView',
    props: true,
    component: () => import(/* webpackChunkName: "ProfileView" */ './components/views/Dashboard/ProfileView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/:id/edit',
    name: 'ProfileEdit',
    props: true,
    component: () => import(/* webpackChunkName: "ProfileEdit" */ './components/views/Dashboard/ProfileEdit.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/:id/report/:reportId',
    name: 'ProfileReport',
    props: true,
    component: () => import(/* webpackChunkName: "ProfileReport" */ './components/views/Dashboard/ProfileReport.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/organisations-management/',
    name: 'OrganisationsManager',
    component: () => import(/* webpackChunkName: "OrganisationsManager" */ './components/views/Dashboard/OrganisationsManager.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/organisations-management/create',
    name: 'OrganisationsAdd',
    component: () => import(/* webpackChunkName: "OrganisationsAdd" */ './components/views/Dashboard/OrganisationsAdd.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/organisations-management/:id',
    name: 'OrganisationsEdit',
    props: true,
    component: () => import(/* webpackChunkName: "OrganisationsEdit" */ './components/views/Dashboard/OrganisationsEdit.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
    },
    {
        path: '/admin-profile/:userId',
        name: 'AdminProfileView',
        props: true,
        component: () => import(/* webpackChunkName: "AdminProfileView" */ './components/views/Dashboard/AdminProfileView.vue'),
        meta: {
            requiresAuth: true,
            requiresAdmin: true
        }
    },
]

