import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// Import Bootstrap Vue
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)

// Import Axios for API calls
import Axios from 'axios'
// Axios.defaults.withCredentials = true

// Import API endpoints
import {apiConfig} from './apiConfig'
Vue.prototype.$api = apiConfig

// Import UI values for various fields
import {uiValues} from './uiValues'
Vue.prototype.$uiValues = uiValues

// Token decoder
import {decodeToken} from './methods/decodeToken'
Vue.prototype.$decodeToken = decodeToken

// Error global handling
import {handleError} from './methods/handleError'
Vue.prototype.$handleError = handleError

// Scrollto
import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)

// Form field mask
import VueMask from 'v-mask'
Vue.use(VueMask);

// Parallax
import VueRellax from 'vue-rellax'
Vue.use(VueRellax)


// Dayjs
import VueDayjs from 'vue-dayjs-plugin'
Vue.use(VueDayjs)


// Custom select
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

// VeeValidate and changes to dictionary
import { ValidationObserver, ValidationProvider, extend, configure, setInteractionMode } from 'vee-validate';
import { alpha, required, email, numeric, regex, min, max, length, alpha_num, min_value, max_value, integer  } from 'vee-validate/dist/rules';
setInteractionMode('eager');
configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
})
extend('integer', integer);
extend('min', min);
extend('length', length);
extend('min_value', min_value);
extend('max_value', max_value);
extend('alpha', alpha);
extend('alpha_num', alpha_num);
extend('email', email);
extend('max', {
  ...max,
  message: 'Maximum number of characters exceeded'
});
extend("regex", regex);
extend("numeric", numeric);
extend('numericNoName', {
  ...numeric,
  message: 'Invalid field value'
});
extend('required', {
  ...required,
  message: 'This field is required'
});
extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match'
});
extend('confirmEmail', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Email confirmation does not match'
});
extend('confirmPhone', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Number confirmation does not match'
});
extend('phoneNumber', {
  validate(value) {
    return (/^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?(\d{4}|\d{3}))?$/.test(value));
  },
  message: 'Phone number is not valid. Enter the number in the following format: +447899000111'
});
extend('postcode', {
  validate(value) {
    return (/^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/.test(value));
  },
  message: 'Enter a valid UK postcode (example: NG17 8AA)'
});
extend('greaterOrEqual', {
  params: ['target'],
  validate(value, { target }) {
    return value >= target;
  },
  message: "Invalid value"
});
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);


// ==== Custom componenent
import LoadingScreen from './components/modules/LoadingScreen.vue'
Vue.component('LoadingScreen', LoadingScreen)

import NetworkIndicator from './components/modules/NetworkIndicator.vue'
Vue.component('NetworkIndicator', NetworkIndicator)

import AlertMsg from './components/modules/AlertMsg.vue'
Vue.component('AlertMsg', AlertMsg)

import ModalInfo from './components/modules/ModalInfo.vue'
Vue.component('ModalInfo', ModalInfo)

import Navigation from './components/modules/Navigation.vue'
Vue.component('Navigation', Navigation)

import NavigationSurvey from './components/modules/NavigationSurvey.vue'
Vue.component('NavigationSurvey', NavigationSurvey)

import Footer from './components/modules/Footer.vue'
Vue.component('Footer', Footer)


// ==== Import custom methods
import {showInfo} from './methods/showInfo'
Vue.prototype.$showInfo = showInfo

import {watchForHover} from './methods/watchForHover'
Vue.prototype.$watchForHover = watchForHover

import { randomGestureImage } from './methods/randomGestureImage'
Vue.prototype.$randomGestureImage = randomGestureImage


Vue.prototype.$http = Axios
Vue.config.productionTip = false

// Globally accessible mixins
Vue.mixin({
  methods: {

    alertMsgShow: function (msg, success) {
      this.$store.state.alertMsg = {
        msg: msg,
        success: success,
        show: true
      }
    },

    blurButtons: function() {
      console.log('blur buttons')
      setTimeout(function() {
        document.activeElement.blur()
      }, 150);
    },

    displayTextInLabel: function ({ text }) {
      return text
    },

    formatPhoneNumber: function (prefix, phone) {
      if (phone.charAt(0) === '0') {
        phone = phone.substring(1);
      }
      var str = prefix + phone;
      str = str.replace(/\s/g, '');
      // if (str.charAt(0) === '+') {
      //   str = str.replace(/\+/g, "00")
      // }
      return str
    },

    goBack: function () {
      this.$router.go(-1);
    },

    inviteParent (inviteEmail, sendEmail) {
      var self = this
      return self.$http({
        method: 'post', 
        url: self.$api.FamilyInviteParent,
        params: {
          email: inviteEmail,
          sendEmail: sendEmail
        },
        headers: {'Authorization': 'bearer ' + self.$store.state.jwt } 
      })
        .then(function (response) {
          console.log(response)
        })
        .catch(function (error) {
          console.error('Error', error)
          self.alertMsgShow("Additional parent email not sent", false);
        })
    },

    onePropIsTrue: function (obj) {
      for (var o in obj) {
        if (obj[o] === true) {
          return true;
        }
      }
      return false;
    },

    passwordValidate (pass) {
      console.log(pass.length)
      var passwordValidation = {
        charLength: false,
        uppercase: false,
        lowercase: false,
        digit: false,
        special: false,
        errorDisplay: false,
        valid: false
      }
      if (pass.length >= 8) {
        passwordValidation.charLength = true
      } else {
        passwordValidation.charLength = false
      }
      if ((/[a-z]/.test(pass))) {
        passwordValidation.lowercase = true
      } else {
        passwordValidation.lowercase = false
      }
      if ((/[A-Z]/.test(pass))) {
        passwordValidation.uppercase = true
      } else {
        passwordValidation.uppercase = false
      }
      if ((/[0-9]/.test(pass))) {
        passwordValidation.digit = true
      } else {
        passwordValidation.digit = false
      }
      if ((/[{}[\]£;:'<>.,?/`¬~|!@#$%^"&*)(+=_-]/.test(pass))) {
        passwordValidation.special = true
      } else {
        passwordValidation.special = false
      }
      var v = passwordValidation
      if (v.charLength && v.lowercase && v.uppercase && v.digit && v.special) {
        passwordValidation.valid = true
      } else {
        passwordValidation.valid = false
      }
      return passwordValidation
    },

    resetStoreData () {
      this.$store.state.user = {}
    },
    
    signOut: function () {
      this.$router.push('/')
      this.$store.state.user = null
      localStorage.removeItem('jwt')
    },

    signOutReload: function () {
      location.reload();
    }
  }
})

// Clickjacking security solution
try { top.document.domain } catch (e) {
  var f = function() { document.body.innerHTML = ''; }
  setInterval(f, 1);
  if (document.body) document.body.onload = f;
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
