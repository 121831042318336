export default [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ './components/views/Brochure/Home.vue')
  },
  {
    path: '/taycr',
    name: 'SurveyTaycr',
    component: () => import(/* webpackChunkName: "SurveyTaycr" */ './components/views/Survey/SurveyTaycr.vue')
  },
  {
    path: '/taycr/result',
    name: 'SurveyTaycrResult',
    component: () => import(/* webpackChunkName: "SurveyTaycrResult" */ './components/views/Survey/SurveyTaycrResult.vue')
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import(/* webpackChunkName: "Faq" */ './components/views/Brochure/Faq.vue')
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "PrivacyPolicy" */ './components/views/Brochure/PrivacyPolicy.vue')
  },
  {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    component: () => import(/* webpackChunkName: "TermsOfUse" */ './components/views/Brochure/TermsOfUse.vue')
  },
  {
    path: '/register-interest',
    name: 'RegisterInterest',
    component: () => import(/* webpackChunkName: "RegisterInterest" */ './components/views/Brochure/RegisterInterest.vue')
  },
  {
    path: '/interest-registered',
    name: 'RegisterInterestThanks',
    component: () => import(/* webpackChunkName: "RegisterInterestThanks" */ './components/views/Brochure/RegisterInterestThanks.vue')
  },
  {
    path: '*',
    redirect: '/dashboard'
  },
  { 
    path: '/index.html', 
    redirect: '/' 
  }
]
