<template>
  <div id="app" class="app">
    
    <ModalInfo />
    <Navigation v-if="appSection !== 'survey'" />
    <NavigationSurvey v-if="appSection === 'survey'" />

    <transition name="fade" mode="out-in">
      <LoadingScreen v-if="$store.state.loadingProgress"></LoadingScreen>
    </transition>

    <transition name="fade" mode="out-in">
      <NetworkIndicator v-if="!$store.state.connectivity"></NetworkIndicator>
    </transition>
    
    <transition name="fade" mode="out-in">
      <AlertMsg></AlertMsg>
    </transition>

    <transition name="fade" mode="out-in" appear>
      <router-view :key="$route.name + ($route.params.id || '')" class="app-content"></router-view>
    </transition> 

  </div>
</template>

<script>

export default {
  name: 'appMain',
  data () {
    return {}
  },
  computed: {
    appSection () {
      return this.$store.state.appSection
    },
    connectivity () {
      return this.$store.state.connectivity
    },
    showReloadModalComp () {
      return this.$store.state.showReloadModal
    }
  },
  created: function () {
    var self = this
    self.$store.state.showReloadModal = false
    // Watch for hover - detect if device has is touch enabled to remove hover states 
    self.$watchForHover()

    // Listen for online / offline status
    window.addEventListener('online',  this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    document.addEventListener('visibilitychange', this.visibilityChange);
  },
  methods: {
    updateOnlineStatus () {
      var status = navigator.onLine ? true : false;
      this.$store.state.connectivity = status
    },
    visibilityChange() {
      var self = this;
      if (document.hidden && this.$route.meta.requiresAuth === true) {
        console.log('call auth')
        self.$http({
          method: 'get', 
          url: self.$api.AuthTokenValidate,
          headers: {'Authorization': 'bearer ' + self.$store.state.jwt } 
        })
          .then(function (response) {
            console.log(response)
            if (response.status !== 200) {
              console.log('token expired')
              self.$store.commit('signOut')
            }
          })
          .catch(function (error) {
            console.log(error)
            self.$store.commit('signOut')
          })
      }
    }
  },
  watch: {
    connectivity (val) {
      if (!val) {
        console.log('network down')
      } else {
        console.log('network up')
      }
    },
    showReloadModalComp () {
      if (this.showReloadModalComp === true) {
        console.log('change')
        this.$bvModal.show('modal-reload')
      }
    }
  }
}
</script>

<style lang="scss">
@import "sass/bootstrap4/bootstrap";
@import "sass/custom/accordion";
@import "sass/custom/alertmsg";
@import "sass/custom/animations";
@import "sass/custom/app";
@import "sass/custom/backgrounds";
@import "sass/custom/box-button";
@import "sass/custom/content-form";
@import "sass/custom/font-size";
@import "sass/custom/form-elements";
@import "sass/custom/footer";
@import "sass/custom/helpers";
@import "sass/custom/hero-promo";
@import "sass/custom/hero-standard";
@import "sass/custom/icons-fontello";
@import "sass/custom/img";
@import "sass/custom/input-plusminus";
@import "sass/custom/list-requirements";
@import "sass/custom/nav";
@import "sass/custom/preloader";
@import "sass/custom/score";
@import "sass/custom/survey-graph";
@import "sass/custom/survey-progress";
@import "sass/custom/wrapper";
@import "sass/custom/vue-select/vue-select";
</style>
