<template>
  <b-modal id="modal-info" hide-footer title-class="h3">
    <template v-slot:modal-title>
      {{$store.state.modalInfo.title}}
    </template>
    <div class="d-block" v-html="$store.state.modalInfo.content"></div>
    <div class="text-center">
      <button type="button" class="btn btn-lg btn-warning mt-3" @click="$bvModal.hide('modal-info')">Close</button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalInfo',
}
</script>
