export function handleError(code) {
  console.log(code)

  switch (code) {
    case 401:
      this.$store.state.loadingProgress = false
      this.$bvModal.hide()
      this.$showInfo('Unauthorized')
      this.signOut()
      break
    case 403:
      this.$store.state.loadingProgress = false
      this.$bvModal.hide()
      this.$showInfo('Unauthorized')
      this.$router.push('/dashboard')
      break
    case 400:
      this.$store.state.loadingProgress = false
      this.$bvModal.hide()
      this.$showInfo('ErrorTryAgain')
      break
    case 500:
      this.$store.state.loadingProgress = false
      this.$bvModal.hide()
      this.$showInfo('ErrorTryAgain')
      break
    case 204:
      this.$store.state.loadingProgress = false
      this.$bvModal.hide()
      this.$router.push('/dashboard/')
      this.$showInfo('ErrorNotFound')
      break
    case 'NewPasswordError':
      this.$store.state.loadingProgress = false
      this.$bvModal.hide()
      this.$showInfo('NewPasswordError')
  }
  
}
