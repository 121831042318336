<template>
  <div class="alertmsg" v-bind:class="{'bg-success': success, 'bg-danger': !success, 'alertmsg-show': showAlert}" v-on:click="showAlert = false">
    <div class="container-fluid wrapper-medium">
      <div class="row">
        <div class="col-12 text-center" v-bind:class="{'text-dark': success, 'text-white': !success}">
          <p class="lead pt-3">{{msg}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlertMsg',
  data () {
    return {
      showAlert: false
    }
  },
  computed: {
    show () {
      return this.$store.state.alertMsg.show
    },
    msg () {
      return this.$store.state.alertMsg.msg
    },
    success () {
      return this.$store.state.alertMsg.success
    },
  },
  created: function () {

  },
  beforeDestroy() {},
  watch: {
    show () {
      var self = this
      if (this.show === true) {
        this.showAlert = true
        setTimeout(() => {
          self.showAlert = false
          self.$store.state.alertMsg.show = false
        }, 5000);
      }


    }
  }
}
</script>