<template>
  <div class="preloader loading-screen">
    <div class="preloader-content">
      <h2 class="text-white">
        <span class="icon-spin1 anim-spin"></span> Please wait
      </h2>
      <p>Loading data...</p>

      <transition name="fade" mode="out-in">
        <div v-if="showReload" class="mt-4">

          <div class="card mb-3">
            <div class="card-body text-center text-dark">
              <h3>Something's not right</h3>
              <p>It's taking longer than usual to load the data.<br>Please check your internet connection or reload this page.</p>
              <button class="btn btn-warning btn-lg" v-on:click="signOutReload()">Reload</button>
            </div>
          </div>

        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingScreen',
  data () {
    return {
      showReload: false
    }
  },
  created: function () {
    var self = this
    this.showReload = false
    if (window.loadingTime !== undefined && window.loadingTime !== null) {
      clearTimeout(window.loadingTime);
    }
    window.loadingTime = setTimeout(function() {
      self.showReload = true
    }, 25000);
  },
  beforeDestroy() {
    if (window.loadingTime !== undefined && window.loadingTime !== null) {
      clearTimeout(window.loadingTime)
    }
    this.showReload = false
  },
}
</script>
