const domain = process.env.VUE_APP_API
const apiConfig = {
  AccountDelete: domain + '/Account/Delete',
  AccountPersonalDetailsGet: domain + '/Account/PersonalDetails/Get',
  AccountPersonalDetailsUpdate: domain + '/Account/PersonalDetails/Update',
  AccountPhoneNoGet: domain + '/Account/PhoneNo/Get',
  AccountRegister: domain + '/Account/Register',
  AccountRegisterAddPhoneNumber: domain + '/Account/Register/AddPhoneNumber',
  AccountRegisterConfirmEmail: domain + '/Account/Register/ConfirmEmail',
  AccountRegisterConfirmPhoneNumber: domain + '/Account/Register/ConfirmPhoneNumber',
  AccountUpdateEmail: domain + '/Account/UpdateEmail',
  AccountUpdateEmailConfirm: domain + '/Account/UpdateEmail/Confirm',
  AccountUpdatePassword: domain + '/Account/UpdatePassword',
  AccountUpdatePhoneNumber: domain + '/Account/UpdatePhoneNumber',
  AccountUpdatePhoneNumberConfirm: domain + '/Account/UpdatePhoneNumber/Confirm',
  AdminAllGet: domain + '/Admin/All/Get',
  AdminDelete: domain + '/Admin/Delete',
  AdminInvite: domain + '/Admin/Invite',
  AdminInvitesGet: domain + '/Admin/Invites/Get',
  AdminInvitesRevoke: domain + '/Admin/Invites/Revoke',
  AdminRegister: domain + '/Admin/Register',
  AdminValidateInvite: domain + '/Admin/ValidateInvite',
  AdminProfilesGetByUserId: domain + '/Questionnaire/Profiles/GetByUserId',
  AdminProfileGet: domain + '/Questionnaire/Profiles/GetProfileById',
  AdminQuestionnaireAllGet: domain + '/Questionnaire/AdminAllGet',
  AuthForgotPassword: domain + '/Auth/ForgotPassword',
  AuthLogin: domain + '/Auth/Login',
  AuthResetPassword: domain + '/Auth/ResetPassword',
  AuthTokenValidate: domain + '/Auth/Token/Validate',
  AuthTwoFactorLogin: domain + '/Auth/TwoFactorLogin',
  OrganisationAllGet: domain + '/Organisation/All/Get',
  OrganisationCreate: domain + '/Organisation/Create',
  OrganisationCreditsGet: domain + '/Organisation/Credits/Get',
  OrganisationDelete: domain + '/Organisation/Delete',
  OrganisationUpdate: domain + '/Organisation/Update',
  QuestionnaireAllGet: domain + '/Questionnaire/All/Get',
  QuestionnaireCancel: domain + '/Questionnaire/Cancel',
  //QuestionnaireVisibility: domain + '/Questionnaire/Visibility',
  QuestionnaireCreate: domain + '/Questionnaire/Create',
  QuestionnaireGet: domain + '/Questionnaire/Get',
  QuestionnaireProfilesCreate: domain + '/Questionnaire/Profiles/Create',
  QuestionnaireProfilesDelete: domain + '/Questionnaire/Profiles/Delete',
  QuestionnaireProfilesGet: domain + '/Questionnaire/Profiles/Get',
  QuestionnaireProfilesUpdate: domain + '/Questionnaire/Profiles/Update',
  QuestionnaireResultsGet: domain + '/Questionnaire/Results/Get',
  QuestionnaireStatusGet: domain + '/Questionnaire/Status/Get',
  QuestionnaireSubmit: domain + '/Questionnaire/Submit',
  QuestionnaireShare: domain + '/Questionnaire/Share',
  QuestionnaireSummaryGet: domain + '/Questionnaire/Summary/Get',
  RegisterInterest: domain + '/Contact/RegisterInterest',
  TherapistAdminInvite: domain + '/Therapist/Admin/Invite',
  TherapistAllAdminGet: domain + '/Therapist/All/Admin/Get',
  TherapistAllGet: domain + '/Therapist/All/Get',
  TherapistDelete: domain + '/Therapist/Delete',
  TherapistInvite: domain + '/Therapist/Invite',
  TherapistInvitesAdminGet: domain + '/Therapist/Invites/Admin/Get',
  TherapistInvitesAdminRevoke: domain + '/Therapist/Invites/Admin/Revoke',
  TherapistInvitesGet: domain + '/Therapist/Invites/Get',
  TherapistInvitesRevoke: domain + '/Therapist/Invites/Revoke',
  TherapistUpdate: domain + '/Therapist/Update',
  TherapistValidateInvite: domain + '/Therapist/ValidateInvite',
}
export { apiConfig }