export function showInfo(section) {

  var modal = {}

  switch (section) {
    case 'ErrorTryAgain':
      modal.title = 'Something went wrong'
      modal.content = "System error occurred. Please refresh the page and try again or contact Administrator if the problem persists."
      break 
    case 'ErrorNotFound':
      modal.title = 'Item not found'
      modal.content = "System error occurred. Please refresh the page and try again or contact Administrator if the problem persists."
      break 
    case 'Unauthorized':
      modal.title = "You're not authorized to perform this operation"
      modal.content = ""
      break
    case 'VerificationPhoneNumber':
      modal.title = "Verification phone number"
      modal.content = "<p>Please provide <strong>your valid mobile number</strong> as we will use it to send you a verification text message.</p>"
      break
    case 'EmailUpdated':
      modal.title = "Confirmation email sent"
      modal.content = "You'll receive an email with a confirmation link."
      break 
    case 'ContactPhoneNumber':
      modal.title = "Contact phone number"
      modal.content = "<p>This phone number is for your records in case you want to contact the therapist.</p><p>This is not a verfication phone number required to login.</p>"
      break
    case 'OrganisationAdmin':
      modal.title = "Organisation Admin"
      modal.content = "<p>Organisation Admininistrators can add, delete and update other members of the organisation.</p>"
      break
     case "OrganisationSuperAdmin":
       modal.title = "Organisation Super Admin"
       modal.content = "<p>Organisation Super Admininistrators have privilages over therapists and can see there family profiles, send questionnaires etc.</p>"
       break
  }
  this.$store.state.modalInfo = modal
  console.log(this.$store.state.modalInfo)

  this.$bvModal.show('modal-info')
}
