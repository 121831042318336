<template>
  <section class="footer pt-4">
    <footer class="container-fluid wrapper-medium">
      <div class="row pb-3">

        <div class="col-12 order-1 col-md-6 text-center text-md-left">
          <div class="mb-4">
            <b-link to="/" title="TAYC-R Homepage" class="footer-logo">
              <img src="/static/img/tayc-r-logo-white.svg" alt="TAYC-R logo" width="120" height="56">
            </b-link>
          </div>
          <p>© {{$store.state.appYear}} Adoptionplus Ltd. All rights reserved. Adoptionplus Ltd is a part of Barnardo’s group. (Registered Charity 216250)</p>
        </div>

        <div class="col-6 col-md-3 mb-4 order-3">
          <ul class="footer-links" v-if="userSignedIn === false">
            <li><b-link to="/register-interest">Register interest</b-link></li>
            <li><b-link to="/faq">FAQs</b-link></li>
          </ul>
          <ul class="footer-links" v-if="userSignedIn !== false">
            <li><b-link v-on:click="signOut()">Sign out</b-link></li>
            <li><b-link to="/faq">FAQs</b-link></li>
          </ul>
        </div>
        <div class="col-6 col-md-3 mb-4 order-4">
          <ul class="footer-links">
            <li><b-link href="http://www.adoptionplus.co.uk/contact">Contact us</b-link></li>
            <li><b-link to="/privacy-policy" title="Privacy Policy">Privacy Policy</b-link></li>
            <li><b-link to="/terms-of-use" title="Terms of Use">Terms of Use</b-link></li>
          </ul>
        </div>
      </div>
    </footer>

    <div class="footer-sub pt-4 pb-3">
      <div class="container-fluid wrapper-medium">
        <div class="row">
          <div class="col-12 col-md-6 order-md-1 text-center text-md-left">
            <p class="mb-0">App ver. {{$store.state.appVersion}}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    userSignedIn () {
      if (this.$store.state.user === null || this.$store.state.user.email === null || this.$store.state.user.email === '') {
        return false
      } else {
        return true
      }
    },
  },
}
</script>